import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout/layout'

const BodegaPage = () => (
  <Layout>
    <h1>Historia de la Bodega</h1>
    <p>La construcción de Bodega Iniesta comienza en los años 90 cuando José Antonio Iniesta inicia este proyecto con una plantación de 10 hectáreas de viñedo. Tras varios años y viendo los resultados obtenidos, se continuó su trabajo en la viticultura, plantando diversas variedades y aumentando la extensión vitícola hasta las más de 300 hectáreas actuales.</p>

    <p>Bodega Iniesta se encarga de todo el proceso de elaboración del vino: desde los viñedos hasta su comercialización. A principios del año 2000 crece la idea de construir una bodega propia para poder elaborar vinos propios a partir de sus propios viñedos. Este sueño se hace realidad en el año 2010, cuando culmina la construcción de la Bodega.</p>

    <h2>Los comienzos del proyecto</h2>
    <p>Emplazada en el corazón de La Manchuela, entre las cuencas de los ríos Cabriel y Júcar, se encuentra  Bodega Iniesta, en Fuentealbilla, provincia de Albacete. Una bodega construida para llevar a cabo el sueño de la familia Iniesta Lujan.</p>
 
    <p>Actualmente cuenta con más de 300 hectáreas de viña, incluyendo las variedades autóctonas: Macabeo, Bobal, Graciano, etc.,  y las variedades internacionales: Chardonnay, Petit Verdot, Sauvignon Blanc, etc.; situadas en la D.O. MANCHUELA, junto con la bodega equipada con la última tecnología en la elaboración de vinos.</p>

    <h2>De un sueño a la realidad</h2>
    <p>La Bodega, construida en el año 2010, ha culminado el sueño de la familia Iniesta Luján que en su experiencia viticultora siempre mantuvieron en mente, el poder elaborar sus propios vinos de su propiedad.</p>
 
    <p>Bodega y viñedos se encuentran ubicados en Fuentealbilla, en el corazón de La Manchuela, entre la Meseta y el Mediterráneo, lo que confiere a la zona un microclima ideal para el cultivo de la vid y para poder disfrutar de sus mejores características.</p>

    <hr />

    <p>La bodega está equipada y diseñada para respetar al máximo las calidades y características, tanto de la uva como del terreno donde se ubican. La filosofía es que, cuanto más respetuosos y menos intervengamos sobre lo que nos llega de la naturaleza, mejor calidad y personalidad tendrán nuestros elaborados.</p>
    
    <p>La Finca y la Bodega están dirigidos por un excelente equipo técnico de agrónomos y enólogos de reconocido prestigio bajo el paraguas, la ilusión y el esfuerzo de la familia Iniesta Luján. El objetivo no es otro que el de trasladar lo mejor de nuestra tierra al mercado nacional e internacional, satisfaciendo y enamorando a los paladares de los consumidores y amantes del vino.</p>
    <Link to="/">Back</Link>
  </Layout>
)

export default BodegaPage
